import { colors } from '@ld/utils';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import AppBar from '@mui/material/AppBar';

export const StyledAppBar = styled(AppBar)`
  && {
    padding: 5px 0px;
    background: ${colors.black1};
  }
`;

export const StyledLink = styled(Link)`
  &,
  &:link {
    font-style: 18px;
    text-decoration: none;
    color: ${colors.black1};
    backface-visibility: hidden;
    font-family: 'Montserrat', sans-serif;
  }
`;

export const LogoLink = styled(Link)`
  &,
  &:link {
    text-decoration: none;
    color: ${colors.black1};
    backface-visibility: hidden;
    font-family: 'Montserrat', sans-serif;
  }
`;

export const DrawerContainer = styled.div`
  padding: 16px;
  width: 300px;
  flex: 1;
  background: ${colors.black1};
`;

export const DrawerHeader = styled.div`
  margin-bottom: 16px;
`;

export const DrawerContent = styled.div`
  display: flex;
  flex-direction: column;
`;

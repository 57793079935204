import * as React from 'react';
import { Route, RouteProps } from 'react-router-dom';
// @ld
import { PageLoader, useBreakpoints } from '@ld/core';
// @custom
import AppBar from '../app-bar';
import Sidebar from '../sidebar';
import { AppMain } from './styles';
import useAuthRedirect from './use-auth-redirect';

const AppLayout = ({ children }: { children: React.ReactNode }) => {
  const { mdUp } = useBreakpoints();
  return (
    <>
      {mdUp ? <Sidebar /> : <AppBar />}
      <AppMain>{children}</AppMain>
    </>
  );
};

const AppLayoutRoute = ({
  component: Component,
  location,
  children,
  ...rest
}: RouteProps & { children?: React.ReactNode }) => {
  useAuthRedirect({ location });

  return (
    <Route
      {...rest}
      render={(props) => (
        <AppLayout>
          <React.Suspense fallback={<PageLoader />}>
            {Component ? <Component {...props} /> : children}
          </React.Suspense>
        </AppLayout>
      )}
    />
  );
};

export default AppLayoutRoute;

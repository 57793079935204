import * as React from 'react';
import Box from '@mui/material/Box';

// @custom
import BrandMark from '../brand-mark';
import { StyledFooter, Text } from './styles';

const Footer = () => {
  return (
    <StyledFooter>
      <BrandMark />
      <Box mb={1} />
      <Text>
        {'Copyright © '}
        Lifedocs {new Date().getFullYear()}
        {'.'}
      </Text>
    </StyledFooter>
  );
};

export default React.memo(Footer);

import Box from '@mui/material/Box';
import { useHistory } from 'react-router-dom';
import { IoLogOutOutline } from 'react-icons/io5';
// @ld
import { UnstyledLink } from '@ld/core';
// @custom
import {
  removeTwoFactorVerified,
  removeSigninEmailNotificationSentFlag
} from 'services/session-storage';
import Logo from '../logo';
import useUser from 'hooks/use-user';
import MenuItems from '../menu-items';
import { useSignOut } from 'api/auth';
import { organisationsUrl } from 'constants/urls';
import { ASide, Footer, Button, Content } from './styles';

const Sidebar = () => {
  const { setUser } = useUser();
  const history = useHistory();

  const { handleSignOut } = useSignOut({
    onSuccess: () => {
      setUser(null);
      removeTwoFactorVerified();
      removeSigninEmailNotificationSentFlag();
      history.replace('/');
    }
  });

  return (
    <ASide>
      <Box p='30px'>
        <UnstyledLink to={organisationsUrl}>
          <Logo />
        </UnstyledLink>
      </Box>
      <Content>
        {MenuItems.map((Item, index) => (
          <Item key={`sidebar-menu-item-${index}`} />
        ))}
      </Content>
      <Footer>
        <Button
          fullWidth
          startIcon={<IoLogOutOutline size={30} />}
          onClick={() => {
            handleSignOut();
          }}
        >
          Logout
        </Button>
      </Footer>
    </ASide>
  );
};

export default Sidebar;

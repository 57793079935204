import { NavLinkProps } from 'react-router-dom';
import {
  IoPersonCircle,
  IoPersonCircleOutline,
  IoBusiness,
  IoBusinessOutline,
  IoPricetags,
  IoPricetagsOutline
} from 'react-icons/io5';

// @custom
import { StyledLink, IconWrapper, ActiveBorder } from './styles';
import { organisationsUrl, promoCodesUrl, myAccountUrl } from 'constants/urls';

const StyledNavLink = ({ children, ...rest }: NavLinkProps) => (
  <StyledLink {...rest} activeClassName='active'>
    <ActiveBorder />
    {children}
  </StyledLink>
);

const Organisations = () => (
  <StyledNavLink to={organisationsUrl}>
    <IconWrapper>
      <IoBusinessOutline size={24} />
      <IoBusiness size={24} />
    </IconWrapper>
    Organisations
  </StyledNavLink>
);

const PromoCodes = () => (
  <StyledNavLink to={promoCodesUrl}>
    <IconWrapper>
      <IoPricetagsOutline size={24} />
      <IoPricetags size={24} />
    </IconWrapper>
    Promo Codes
  </StyledNavLink>
);

const Account = () => (
  <StyledNavLink to={myAccountUrl}>
    <IconWrapper>
      <IoPersonCircleOutline size={24} />
      <IoPersonCircle size={24} />
    </IconWrapper>
    Account
  </StyledNavLink>
);

export const MobileMenuItems = [Organisations, PromoCodes, Account];

export default [Organisations, PromoCodes, Account];

import * as React from 'react';
import { IoMenu } from 'react-icons/io5';
import { useHistory } from 'react-router-dom';
// @mui
import {
  Grid,
  List,
  Button,
  Toolbar,
  ListItem,
  IconButton,
  SwipeableDrawer
} from '@mui/material';
// @custom
import { LogoLink, DrawerContainer, DrawerHeader, DrawerContent } from './styles';
import {
  removeTwoFactorVerified,
  removeSigninEmailNotificationSentFlag
} from 'services/session-storage';
import Logo from 'components/logo';
import useUser from 'hooks/use-user';
import { useSignOut } from 'api/auth';
import { track } from 'utils/segment';
import { StyledAppBar } from './styles';
import { MobileMenuItems } from '../menu-items';
import { organisationsUrl } from 'constants/urls';

const MobileAppBar = ({ hideProfile }: { hideProfile?: boolean }) => {
  const { user, setUser } = useUser();
  const history = useHistory();
  const [open, setOpen] = React.useState<boolean>(false);
  const { handleSignOut } = useSignOut({
    onSuccess: () => {
      track('Sign Out');
      setUser(null);
      removeTwoFactorVerified();
      removeSigninEmailNotificationSentFlag();
      history.replace('/');
    }
  });
  return (
    <>
      <StyledAppBar position='static'>
        <Toolbar>
          <Grid container justifyContent='space-between' alignItems='center' spacing={1}>
            <LogoLink to={organisationsUrl}>
              <Logo />
            </LogoLink>
            {hideProfile ? (
              <div />
            ) : (
              <IconButton onClick={() => setOpen(true)}>
                <IoMenu color='white' />
              </IconButton>
            )}
          </Grid>
        </Toolbar>
      </StyledAppBar>
      <SwipeableDrawer
        open={open}
        anchor='right'
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
      >
        <DrawerContainer>
          <DrawerHeader>
            <LogoLink to={organisationsUrl}>
              <Logo />
            </LogoLink>
          </DrawerHeader>
          <DrawerContent>
            <List component='nav' aria-label='navigation'>
              {MobileMenuItems.map((MenuItem, index) => (
                <ListItem
                  key={`appbar-mobile-menu-item-${index}`}
                  onClick={() => setOpen(false)}
                >
                  <MenuItem />
                </ListItem>
              ))}
              {user && (
                <ListItem>
                  <Button fullWidth onClick={() => handleSignOut()}>
                    Logout
                  </Button>
                </ListItem>
              )}
            </List>
          </DrawerContent>
        </DrawerContainer>
      </SwipeableDrawer>
    </>
  );
};

export default React.memo(MobileAppBar);

export const signinUrl = '/';
export const twoFactorAuthenticationUrl = '/two-factor';
export const forgotPasswordUrl = '/forgot-password';
export const authActionUrl = '/auth-action';
export const organisationsUrl = '/organisations';
export const promoCodesUrl = '/promo-codes';
export const unauthorizedUrl = '/unauthorized';

// account and settings
export const myAccountUrl = '/my-account';

class IdleTimer {
  timeout: number; // seconds
  interval: number;
  timeoutTracker: number;
  onTimeout: () => void;
  eventHandler: () => void;
  onTimerUpdate: (second: number) => void;

  constructor({
    timeout,
    onTimeout,
    onTimerUpdate
  }: {
    timeout: number;
    onTimeout: () => void;
    onTimerUpdate: (second: number) => void;
  }) {
    this.timeout = timeout;
    this.onTimeout = onTimeout;
    this.onTimerUpdate = onTimerUpdate;
    this.eventHandler = this.updateExpiredTime.bind(this);
    this.tracker();
    this.startInterval();
  }

  startInterval() {
    this.updateExpiredTime();

    this.interval = window.setInterval(() => {
      const expiredTime = parseInt(sessionStorage.getItem('_expiredTime') || '0', 10);
      this.onTimerUpdate((expiredTime - Date.now()) / 1000);
      if (expiredTime < Date.now()) {
        this.onTimeout();
        this.cleanUp();
      }
    }, 1000);
  }

  updateExpiredTime() {
    if (this.timeoutTracker) {
      clearTimeout(this.timeoutTracker);
    }
    this.timeoutTracker = window.setTimeout(() => {
      sessionStorage.setItem('_expiredTime', `${Date.now() + this.timeout * 1000}`);
    }, 300);
  }

  tracker() {
    window.addEventListener('mousemove', this.eventHandler);
    window.addEventListener('scroll', this.eventHandler);
    window.addEventListener('keydown', this.eventHandler);
    window.addEventListener('uploading', this.eventHandler);
  }

  cleanUp() {
    sessionStorage.removeItem('_expiredTime');
    clearInterval(this.interval);
    window.removeEventListener('mousemove', this.eventHandler);
    window.removeEventListener('scroll', this.eventHandler);
    window.removeEventListener('keydown', this.eventHandler);
    window.removeEventListener('uploading', this.eventHandler);
  }
}
export default IdleTimer;

import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// @custom
import {
  unauthorizedUrl,
  organisationsUrl,
  twoFactorAuthenticationUrl
} from 'constants/urls';
import useUser from 'hooks/use-user';
import { getIsTwoFactorVerified } from 'services/session-storage';

/**
 * Used to redirect away from auth screens if the user is successfully logged in
 */
const useRedirectToApp = () => {
  const { user } = useUser();
  const history = useHistory();

  useEffect(() => {
    if (user) {
      if (!user.emailVerified) {
        history.replace(unauthorizedUrl);
      } else if (user.isTwoFactorEnabled && !getIsTwoFactorVerified()) {
        history.replace(twoFactorAuthenticationUrl);
      } else if (user.sysadmin) {
        history.replace(organisationsUrl);
      } else {
        history.replace(unauthorizedUrl);
      }
    }
  }, [history, user]);

  return null;
};

export default useRedirectToApp;

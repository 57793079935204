import * as React from 'react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// @custom
import useAuth from 'hooks/use-auth';
import { ISysAdminRead } from 'constants/types';
import UserContext from 'context/user-context';
import { SnackbarProvider } from 'context/snackbar-context';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

type AppProviderProps = {
  children: React.ReactNode;
};

const AppProvider = ({ children }: AppProviderProps) => {
  const { user, isInitialising, setUser } = useAuth();

  if (isInitialising) {
    return <div>Loading...</div>;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider>
        <UserContext.Provider
          value={{
            user,
            setUser: (newUser: ISysAdminRead | null) => setUser(newUser)
          }}
        >
          {children}
        </UserContext.Provider>
      </SnackbarProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default AppProvider;

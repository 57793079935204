import { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore/lite';

// @custom
import { ISysAdminRead } from 'constants/types';

export default function useAuth() {
  const [user, setUser] = useState<ISysAdminRead | null>(null);
  const [isInitialising, setInitialising] = useState(true); // Helpful, to update the UI accordingly.

  useEffect(() => {
    const auth = getAuth();
    const db = getFirestore();
    // Listen authenticated user
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      try {
        if (user) {
          // User is signed in.
          // You could also look for the user doc in your Firestore (if you have one):
          const userRef = doc(db, 'users', user.uid);
          const userDoc = await getDoc(userRef);
          const userData = userDoc.exists() ? userDoc.data() : {};
          setUser({
            uid: user.uid,
            email: user.email,
            photoURL: user.photoURL,
            sysadmin: userData?.sysadmin,
            displayName: user.displayName,
            emailVerified: user.emailVerified,
            isTwoFactorEnabled: userData?.isTwoFactorEnabled
          });
        } else {
          setUser(null);
        }
      } catch (error) {
        // Most probably a connection error. Handle appropriately.
      } finally {
        setInitialising(false);
      }
    });

    // Unsubscribe auth listener on unmount
    return () => unsubscribe();
  }, []);

  return { user, isInitialising, setUser };
}

import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
// @ld
import {
  PageLoader,
  GlobalStyles,
  ThemeProvider,
  StagingEnvBanner,
  ErrorFallback
} from '@ld/core';
// @custom
import * as urls from 'constants/urls';
import Segment from 'components/segment';
import PageNotFound from 'pages/page-not-found';
import AppProvider from 'components/app-provider';
import AppIdleTimer from 'components/app-idle-timer';
import AppLayoutRoute from 'components/layouts/app-layout-route';
import AuthLayoutRoute from 'components/layouts/auth-layout-route';

const App = () => {
  return (
    <ThemeProvider>
      <GlobalStyles />
      <AppProvider>
        <Router>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Suspense fallback={<PageLoader />}>
              <Switch>
                <AuthLayoutRoute
                  exact
                  path='/'
                  component={React.lazy(
                    () => import(/* webpackChunkName: 'Signin' */ './pages/auth/signin')
                  )}
                />
                <AuthLayoutRoute
                  exact
                  path={urls.twoFactorAuthenticationUrl}
                  component={React.lazy(
                    () =>
                      import(/* webpackChunkName: 'Signin' */ './pages/auth/two-factor')
                  )}
                />
                <AuthLayoutRoute
                  exact
                  path={urls.forgotPasswordUrl}
                  component={React.lazy(
                    () =>
                      import(
                        /* webpackChunkName: 'ForgotPassword' */ './pages/auth/forgot-password'
                      )
                  )}
                />
                <AuthLayoutRoute
                  exact
                  path={urls.authActionUrl}
                  component={React.lazy(
                    () =>
                      import(/* webpackChunkName: 'AuthAction' */ './pages/auth/action')
                  )}
                />
                <AuthLayoutRoute
                  exact
                  path={urls.unauthorizedUrl}
                  component={React.lazy(
                    () =>
                      import(
                        /* webpackChunkName: 'Unauthorized' */ './pages/auth/unauthorized'
                      )
                  )}
                />
                <Route
                  path={urls.organisationsUrl}
                  component={React.lazy(
                    () =>
                      import(
                        /* webpackChunkName: 'Organisations' */ './pages/organisations'
                      )
                  )}
                />
                <Route
                  path={urls.promoCodesUrl}
                  component={React.lazy(
                    () =>
                      import(/* webpackChunkName: 'PromoCodes' */ './pages/promo-codes')
                  )}
                />
                <AppLayoutRoute
                  path={urls.myAccountUrl}
                  component={React.lazy(
                    () => import(/* webpackChunkName: 'Account' */ './pages/account')
                  )}
                />
                <Route path='*' component={PageNotFound} />
              </Switch>
            </Suspense>
          </ErrorBoundary>
          <Toaster position='bottom-center' />
          <Segment />
          <AppIdleTimer />
          <StagingEnvBanner />
        </Router>
      </AppProvider>
    </ThemeProvider>
  );
};

export default App;

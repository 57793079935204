import { colors } from '@ld/utils';
import { NavLink } from 'react-router-dom';
import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';

export const ActiveBorder = styled.div`
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 5px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: ${colors.green1};
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;

  > svg:first-of-type {
    display: block;
  }

  > svg:last-child {
    display: none;
  }
`;

const getBaseLinkStyle = (props: any) => css`
  &,
  &:link {
    width: 100%;
    height: 50px;
    display: flex;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    padding: 10px 30px;
    font-style: normal;
    position: relative;
    align-items: center;
    text-decoration: none;
    backface-visibility: hidden;
    font-family: 'Montserrat', sans-serif;

    &.active {
      font-weight: 600;
      color: ${colors.green1};

      ${ActiveBorder} {
        display: block;

        ${props.theme.breakpoints.down('sm')} {
          display: none;
        }
      }

      ${IconWrapper} {
        > svg:first-of-type {
          display: none;
        }

        > svg:last-child {
          display: block;
        }
      }
    }

    &:hover {
      color: ${colors.green1};
    }
  }
`;

export const StyledLink = styled(NavLink)`
  ${(props) => getBaseLinkStyle(props)}
`;

export const StyledAnchor = styled.a`
  ${(props) => getBaseLinkStyle(props)}
`;
